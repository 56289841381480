<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_inline_block">
          <div class="mainList_operation_search">
            <span
              >商务名称：
              <el-autocomplete
                class="ipt_width project_width"
                v-model="parameter.businessName"
                :fetch-suggestions="businessNameQuerySearch"
                placeholder="请输入或选择商务名称"
                @select="onProkectNameSelect"
                :popper-append-to-body="false"
              >
              </el-autocomplete
            ></span>
            <span>
              商务负责人：
              <el-select
                class="ipt_width"
                clearable
                v-model="parameter.userName"
                filterable
                placeholder="请输入或者选择商务负责人"
              >
                <el-option
                  v-for="item in selectedList"
                  :key="item.id"
                  :label="item.userName"
                  :value="item.userName"
                >
                </el-option>
              </el-select>
            </span>
            <div class="search_center">
              申请日期：
              <el-date-picker
                class="date_width"
                v-model="createdDate"
                :pickerOptions="pickerOptions"
                type="daterange"
                range-separator="至"
                start-placeholder="申请日期（始）"
                end-placeholder="申请日期（末）"
              >
              </el-date-picker>
            </div>

            <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)"
              >搜索</el-button
            >
            <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
          </div>
        </div>
        <div class="fr">
          <el-button type="primary" @click="drawer = true">审核流程</el-button>
          <el-button type="success" style="padding: 0"
            ><a
              style="display: block; height: 40px; width: 100px; line-height: 40px"
              target="_blank"
              href="https://pro.huitukj.com/iip-api/profile/OPERATION_MANUAL/商务绩效分配与审核操作文档.pdf"
              >操作文档</a
            ></el-button
          >
        </div>
      </div>

      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-tabs v-model="parameter.approvalType" class="fullScreenMainHeader">
            <el-tab-pane label="待审核" name="UNDETERMINED"></el-tab-pane>
            <el-tab-pane label="不通过" name="APPROVAL_REJECT"></el-tab-pane>
            <el-tab-pane label="已审批" name="APPROVAL_PASS"></el-tab-pane>
          </el-tabs>

          <el-table
            :key="Math.random()"
            :data="tableData"
            border
            style="width: 100%"
            height="string"
            v-loading="loading"
          >
            <el-table-column
              align="center"
              type="index"
              label="序号"
              width="50"
              :show-overflow-tooltip="false"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="businessName"
              label="商务名称"
              min-width="320"
              :show-overflow-tooltip="false"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="ownerUnit"
              min-width="240"
              label="业主单位"
              :show-overflow-tooltip="false"
            >
            </el-table-column>
            <el-table-column align="center" prop="contacts" label="业主联系人" width="100">
            </el-table-column>
            <el-table-column align="center" prop="contactsPhone" label="联系电话" width="120">
            </el-table-column>
            <el-table-column
              align="center"
              prop="principalUserName"
              label="商务负责人"
              width="120"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="businessType"
              label="商务类型"
              sortable
              width="120"
            >
              <template slot-scope="scope">
                {{ scope.row.businessType | dict(dictData.businessType) }}
              </template>
            </el-table-column>
            <el-table-column align="center" sortable prop="taskDate" label="申请日期" width="110">
              <template slot-scope="scope">
                {{ scope.row.taskDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="statusName"
              sortable
              min-width="150"
              label="流程进度"
            >
            </el-table-column>
            <el-table-column align="center" fixed="right" label="操作" width="70">
              <template slot-scope="scope">
                <template>
                  <el-button
                    v-if="parameter.approvalType == 'UNDETERMINED'"
                    @click="handleClick(scope.row, 2)"
                    type="text"
                    class="text_Examine_Bgc"
                    size="small"
                  >
                    审核
                  </el-button>
                  <el-button
                    v-else
                    @click="handleClick(scope.row, 1)"
                    type="text"
                    class="text_Details_Bgc"
                    size="small"
                  >
                    详情
                  </el-button>
                </template>
              </template>
            </el-table-column>
          </el-table>

          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="parameter.total"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- <ExaMineList
      v-if="showEditDialog"
      @updateData="getData"
      :showEditDialog.sync="showEditDialog"
      :ID="ID"
      :index="index"></ExaMineList> -->
    <DistriBution
      v-if="showEditDialog"
      @updateData="updateData"
      :showEditDialog.sync="showEditDialog"
      :options="businessManageIofn"
      :Disabled="Disabled"
      :title="title"
      :distriBution="true"
    ></DistriBution>

    <el-dialog width="75%" title="商务绩效分配、审核流程图" :visible.sync="drawer">
      <div style="padding: 5px">
        <img
          src="@/assets/商务绩效分配、审核流程图.png"
          alt="商务绩效分配、审核流程图"
          width="100%"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {
    ExaMineList: () => import('./components/ExaMineList.vue'),
    Crumbs: () => import('@/components/Crumbs.vue'),
    DistriBution: () => import('@/views/publicView/DistriBution.vue'),
  },
  data() {
    return {
      parameter: {
        pageNow: 1,
        pageSize: 50,
        total: 0,
        approvalType: 'UNDETERMINED',
        businessName: null,
        userName: null,
        startDate: null,
        endDate: null,
      },
      pageSize: 0,
      tableData: [],
      isShow: false,
      showEditDialog: false,
      dictData: {
        businessType: [],
      },
      businessManageIofn: {},
      title: '',
      Disabled: false,
      selectedList: [],
      businessList: [],
      loading: false,
      drawer: false,
      createdDate: [],
      pickerOptions: {
        onPick: v => {
          if (v.minDate && !v.maxDate) {
            this.$set(this.createdDate, 0, v.minDate.getTime())
            this.$set(this.createdDate, 1, '')
          }
        },
      },
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
      allUserList: state => state.business.allUserList,
    }),
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.getType()
    this.$api.businessExpense
      .businessNameList()
      .then(res => {
        this.businessList = res.data
      })
      .catch(err => {
        console.log(err)
      })
    if (this.allUserList.length <= 0) {
      this.getSelectedList()
    } else {
      this.selectedList = this.allUserList
    }
  },
  watch: {
    'parameter.approvalType': {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal) {
        this.parameter.pageNow = 1
        this.getData()
      },
    },
    tableData: {
      // immediate:true,//初始化立即执行
      deep: true,
      handler: function (newVal, oldVal) {
        let num = newVal.length
        if (num === 0 && this.parameter.pageNow > 1 && this.parameter.pageNow !== 1) {
          this.parameter.pageNow -= 1
          this.getData()
        }
      },
    },
    createdDate: {
      deep: true,
      handler: function (val) {
        if (val == null) {
          this.createdDate = []
        }
      },
    },
  },
  methods: {
    businessNameQuerySearch(queryString, cb) {
      let businessList = this.businessList.map(item => {
        return { value: item.businessName }
      })
      let results = queryString ? this.businessNameCreateFilter(queryString) : businessList
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    businessNameCreateFilter(queryString) {
      let results = this.businessList.map(item => {
        return { value: item.businessName }
      })
      // 将输入的内容进行拆分，返回值为一个数组
      const queryStringList = queryString.split('')
      if (queryStringList.length > 0) {
        queryStringList.forEach(queryItem => {
          let filterResults = results.filter(nameItem => nameItem.value?.includes(queryItem))
          results = [...filterResults]
        })
      }
      return results
    },
    onProkectNameSelect(e) {
      if (e) {
        this.parameter.businessName = e.value
      }
      this.$forceUpdate()
    },
    // 获取所有人员
    getSelectedList() {
      this.$api.businessManage
        .principalUserNameListNoPage()
        .then(res => {
          this.selectedList = res.data
          this.$store.commit({
            type: 'business/SET_ALL_USER_LIST',
            allUserList: res.data,
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    getType() {
      this.$api.dict
        .listSysDictData('BUSINESS_TYPE', true)
        .then(res => {
          this.dictData.businessType = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    updateData() {
      this.showEditDialog = false
      this.getData()
    },
    handleClick(row, index) {
      this.businessManageIofn = row
      if (index == 1) {
        this.title = '绩效详情'
        this.Disabled = true
        this.businessManageIofn.examine = false
      } else if (index == 2) {
        this.title = '审核详情'
        this.Disabled = false
        this.businessManageIofn.examine = true
      }
      this.showEditDialog = true
    },
    searchFn() {
      this.tableData = this.tableData.filter(v => v.businessName == this.parameter.val)
    },
    async getData() {
      this.loading = true
      if (this.createdDate && this.createdDate != []) {
        this.parameter.startDate = new Date(this.createdDate[0]).getTime()
        this.parameter.endDate = new Date(this.createdDate[1]).getTime()
      } else if (this.createdDate == null) {
        this.parameter.startDate = null
        this.parameter.endDate = null
      }
      const res = await this.$api.businessPerformance.searchTask(this.parameter)
      if (res.data == null) {
        let arr = []
        this.tableData = arr
        this.loading = false
      } else {
        this.tableData = res.data.records
        this.parameter.total = res.data.total
        this.loading = false
      }
    },
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },
    handleReset() {
      this.createdDate = []
      this.parameter.startDate = null
      this.parameter.endDate = null
      this.parameter.pageNow = 1
      this.parameter.businessName = null
      this.parameter.userName = null
      this.getData()
    },
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/config.scss';
</style>
